import { combineReducers } from 'redux';
import SensorReducer from '../features/accessories/reducer';
import AlertConfigurationReducer from '../features/alertConfiguration/reducer';
import AlertReducer from '../features/alerts/reducer';
import AuthReducer from '../features/auth/reducer';
import BrandingsReducer from '../features/branding/reducer';
import CameraReducer from '../features/camera/reducer';
import CarrierReducer from '../features/carriers/reducer';
import clientAdminsPage from '../features/client-admins/reducer';
import ClientReducer from '../features/clients/reducer';
import CommandsReducer from '../features/commands/reducer';
import DashboarReducer from '../features/dashboard/reducer';
import DeviceReducer from '../features/devices/reducer';
import EventsReducer from '../features/events/reducer';
import fuelBillReducer from '../features/fuelControl/reducer';
import gasStationReducer from '../features/gasStation/reducer';
import groupReducer from '../features/groups/reducer';
//Features
import ImpersonateReducer from '../features/impersonate/reducer';
import LanguageReducer from '../features/languages/reducer';
import MaintenancesReducer from '../features/maintenance/reducer';
import MapReducer from '../features/map/reducer';
import TaskSchedulerReducer from '../features/mapWidgets/dateSelector/reducer';
import modelProfileReducer from '../features/modelProfile/reducer';
import DeviceModelReducer from '../features/models/reducer';
import multimediaReducer from '../features/multimedia/reducer';
import PointOfInterestReducer from '../features/pointOfInterest/reducer';
import PositionEditorReducer from '../features/positionEditor/reducer';
import ProfileReducer from '../features/Profile/reducer';
import ReportsReducer from '../features/reports/reducer';
import RoleReducer from '../features/roles/reducer';
import scheduleReducer from '../features/schedule/reducer';
import providersPage from '../features/service-providers/reducer';
import ServicesReducer from '../features/services/reducer';
import SessionReducer from '../features/sessions/reducer';
import SharedDevicesExternal from '../features/shareDevicesExternal/reducer';
import SimReducer from '../features/sims/reducer';
import TempReducer from '../features/temporal/reducer';
import AccountReducer from '../features/users/reducer';
import IntegrationReducer from '../features/webhooks/reducer';
import ConfigurationReducer from './ConfigurationReducer';
import ConnectionReducer from './ConnectionReducer';

const reducers = () =>
	combineReducers({
		providersPage,
		clientAdminsPage,
		account: AccountReducer,
		alert: AlertReducer,
		alertsConfig: AlertConfigurationReducer,
		auth: AuthReducer,
		branding: BrandingsReducer,
		camera: CameraReducer,
		carrier: CarrierReducer,
		client: ClientReducer,
		commands: CommandsReducer,
		configuration: ConfigurationReducer,
		connection: ConnectionReducer,
		dashboard: DashboarReducer,
		device: DeviceReducer,
		deviceModel: DeviceModelReducer,
		events: EventsReducer,
		group: groupReducer,
		impersonate: ImpersonateReducer,
		language: LanguageReducer,
		maintenances: MaintenancesReducer,
		map: MapReducer,
		modelProfile: modelProfileReducer,
		multimedia: multimediaReducer,
		pointOfInterest: PointOfInterestReducer,
		positionEditor: PositionEditorReducer,
		profile: ProfileReducer,
		reports: ReportsReducer,
		role: RoleReducer,
		schedule: scheduleReducer,
		sensors: SensorReducer,
		services: ServicesReducer,
		session: SessionReducer,
		sharedDevicesExternal: SharedDevicesExternal,
		sim: SimReducer,
		temp: TempReducer,
		gasStation: gasStationReducer,
		fuelBill: fuelBillReducer,
		integrationService: IntegrationReducer,
		taskSchedulerData: TaskSchedulerReducer,
	});

export default reducers;
