import axios, { AxiosResponse } from 'axios';
import {
	CreateNonComplianceTemplate,
	NonComplianceTemplate,
	UpdateNonComplianceTemplate,
} from '../entities/nonComplianceTemplate';
import { Result } from '../interfaces';

const BASE_URL = `/v2/non-compliance-template`;

type NonComplianceTemplateServiceType = {
	getTemplates: () => Promise<Result<NonComplianceTemplate[]>>;
	addTemplate: (item: CreateNonComplianceTemplate) => Promise<Result<NonComplianceTemplate>>;
	updateTemplate: (item: UpdateNonComplianceTemplate) => Promise<Result<NonComplianceTemplate>>;
	deleteTemplate: (id: number) => Promise<Result<NonComplianceTemplate>>;
	checkTemplateStatus: (id: number) => Promise<Result<boolean>>;
};

const getTemplates = async (): Promise<Result<NonComplianceTemplate[]>> => {
	const response: AxiosResponse<Result<NonComplianceTemplate[]>> = await axios.get(`${BASE_URL}`);

	return response.data;
};

const addTemplate = async (item: CreateNonComplianceTemplate): Promise<Result<NonComplianceTemplate>> => {
	const response = await axios.post<Result<NonComplianceTemplate>>(BASE_URL, item);
	return response.data;
};

const updateTemplate = async (item: UpdateNonComplianceTemplate): Promise<Result<NonComplianceTemplate>> => {
	const response = await axios.patch<Result<NonComplianceTemplate>>(BASE_URL, item);
	return response.data;
};

const deleteTemplate = async (id: number): Promise<Result<NonComplianceTemplate>> => {
	const response = await axios.delete<Result<NonComplianceTemplate>>(`${BASE_URL}/${id}`);
	return response.data;
};

const checkTemplateStatus = async (id: number): Promise<Result<boolean>> => {
	const response = await axios.get<Result<boolean>>(`${BASE_URL}/checkTemplateStatus/${id}`);
	return response.data;
};

const NonComplianceTemplateService: NonComplianceTemplateServiceType = {
	getTemplates,
	addTemplate,
	updateTemplate,
	deleteTemplate,
	checkTemplateStatus,
};

export default NonComplianceTemplateService;
